import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input, Select } from "antd";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { toTitle } from "@src/helpers/text-helper";
import { MeasureLabelCommon, MeasureLabelSpecific } from "./metric-measure-label";
import { SupportedMeasureType, SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { MEASURE_RATIO_FIREBASE, MEASURE_RETENTION_BY_EVENT_NAME } from "@src/constant/ab-testing";
import { config } from "@src/config";
import { IMemberRationOptions } from "@src/types/ab-testing-exploration";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";

export const MEASURE_EVENT_COUNT = "event_count";
export const DEFAULT_EVENT_NAME = "song_start";

export const MetricMeasure: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { trackEvent } = useTracking();
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();
  const { availableModels, isLoadingMeta, getAvailableMeasures, getAvailableDimensions } =
    useAbTestExplorationContext();
  const metricTabName = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME], form);

  const targetedModel = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);
  const availableMeasures = getAvailableMeasures({ modelAlias: targetedModel });

  const handleInitFilter = (_targetModal: SupportedModelAlias) => {
    if (_targetModal !== SupportedModelAlias.FIREBASE) return;

    const metricItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];
    const targetItem = metricItems.find((item: any) => item.key === targetKey);
    const availableDimensions = getAvailableDimensions({ modelAlias: _targetModal }) ?? [];
    if (availableDimensions.length === 0) return;
    const requiredDimensions = availableDimensions.filter(({ options }) => {
      return Boolean(options?.filter?.required);
    });

    const currentMetricFilter = targetItem?.[ChartFormFieldsEnum.METRIC_FILTER] ?? config.METRIC_FILTER_DEFAULT;

    const currentRules = Array.isArray(currentMetricFilter?.rules) ? currentMetricFilter?.rules : [];
    const newRules: any[] = [];

    currentRules.forEach((rule: any) => {
      newRules.push(rule);
    });

    requiredDimensions?.forEach((dimension) => {
      newRules.push({ field: dimension?.name, operator: "=", value: DEFAULT_EVENT_NAME, id: uuidv4() });
    }, []);

    const newData = {
      ...currentMetricFilter,
      rules: newRules,
    };

    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], newData);
    handleUpdateMetricTabItems(targetKey, [ChartFormFieldsEnum.METRIC_FILTER]);
  };
  return (
    <>
      <Form.Item
        label="Model"
        name={[targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL]}
        required
        rules={[
          {
            message: "Please select a model",
            required: true,
          },
        ]}
      >
        <Select
          loading={isLoadingMeta}
          style={{ width: 200 }}
          onChange={(value) => {
            form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE], []);
            form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_VALUE], undefined);
            form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], config.METRIC_FILTER_DEFAULT);
            form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], []);

            handleUpdateMetricTabItems(targetKey, [
              ChartFormFieldsEnum.METRIC_TARGET_MODEL,
              ChartFormFieldsEnum.METRIC_MEASURE,
              ChartFormFieldsEnum.METRIC_FILTER,
              ChartFormFieldsEnum.METRIC_BREAKDOWN,
            ]);
            trackEvent(trackEventPayload.metricItemFieldUpdated(metricTabName, "model", value));
          }}
          placeholder="Select a model"
        >
          {availableModels.map((alias) => {
            return (
              <Select.Option key={alias} value={alias}>
                {toTitle(alias)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <div className="">
        <Form.Item
          label="Measure"
          name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE]}
          required
          rules={[
            {
              message: "Please select a measure",
              required: true,
            },
          ]}
        >
          <Select
            loading={isLoadingMeta}
            style={{ width: 400 }}
            disabled={!targetedModel}
            onChange={(value, opts: any) => {
              const recordName = opts?.record?.name;
              if (targetedModel === SupportedModelAlias.FIREBASE) {
                const _metricFilter = form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER]);
                form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], {
                  ..._metricFilter,
                  rules: _metricFilter?.rules?.filter((rule: any) => {
                    return rule.field !== "event_name";
                  }),
                });
                handleUpdateMetricTabItems(targetKey, [ChartFormFieldsEnum.METRIC_FILTER]);
                if (value === MEASURE_EVENT_COUNT) {
                  handleInitFilter(form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL]));
                }
              }

              let _measureOptions = opts?.record?.options ?? {};
              if (recordName === MEASURE_RATIO_FIREBASE) {
                _measureOptions = {
                  measure_definition: {
                    denominator: "",
                    numerator: "",
                  },
                  measure_type: SupportedMeasureType.RATIO,
                  matched_regex: opts?.record?.matched_regex,
                  name: opts?.record?.name,
                };
              } else if (recordName === MEASURE_RETENTION_BY_EVENT_NAME) {
                _measureOptions = {
                  measure_type: SupportedMeasureType.PROPORTION,
                  matched_regex: opts?.record?.matched_regex,
                  name: opts?.record?.name,
                };
              }

              form.setFieldsValue({
                [targetKey]: {
                  ...form.getFieldValue(targetKey),
                  [ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]: JSON.stringify(_measureOptions),
                  [ChartFormFieldsEnum.METRIC_MEASURE_TYPE]:
                    recordName === MEASURE_RATIO_FIREBASE
                      ? SupportedMeasureType.RATIO
                      : opts?.record?.options?.measure_type ?? SupportedMeasureType.MEAN,
                  [ChartFormFieldsEnum.METRIC_MEASURE_VALUE]: value,
                  [ChartFormFieldsEnum.NUMERATOR]: undefined,
                  [ChartFormFieldsEnum.DENOMINATOR]: undefined,
                  [ChartFormFieldsEnum.EVENT_NAME]: undefined,
                },
              });
              trackEvent(trackEventPayload.metricItemFieldUpdated(metricTabName, "measure", value));
              handleUpdateMetricTabItems(targetKey, [
                ChartFormFieldsEnum.METRIC_MEASURE,
                ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS,
                ChartFormFieldsEnum.METRIC_MEASURE_VALUE,
                ChartFormFieldsEnum.METRIC_MEASURE_TYPE,
              ]);
            }}
            placeholder="Select a measure"
            options={
              targetedModel
                ? availableMeasures?.map((record: any) => {
                    return {
                      label: <MeasureLabelCommon record={record} />,
                      value: record.name,
                      record,
                    };
                  })
                : []
            }
          />
        </Form.Item>
        <MeasureLabelSpecific targetKey={targetKey} />
      </div>

      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE_VALUE]}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE_TYPE]}>
        <Input />
      </Form.Item>
    </>
  );
};
