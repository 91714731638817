import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { sliceFieldValue } from "@src/pages/ab-test-explore/helper/slice-field-value";
import { Form } from "antd";
import dayjs from "dayjs";

type FilterInfoShowProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const FilterInfoShow: React.FC<FilterInfoShowProps> = ({ targetKey, open, setOpen }) => {
  const { form } = useMetricPerformanceContext();
  const filterWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_FILTER], form);

  const rules = filterWatch?.rules ?? [];
  if (rules.length === 0) return null;
  return (
    <div className="flex flex-wrap items-center">
      <span className="mx-2">Filter:</span>
      {rules.map((rule: any) => {
        let _value: any[] = rule?.value;
        if (typeof rule?.value === "string") {
          _value = [rule?.value];
        } else {
          _value = _value.map((v) => (dayjs(v).isValid() ? dayjs(v).format("DD-MM-YYYY") : v));
        }
        const { result, remaining } = sliceFieldValue({ numSlice: 3, value: _value });
        return (
          <div
            onClick={() => {
              setOpen(true);
            }}
            key={rule?.id + rule?.field}
            className="py-[2px] px-2 border-[1px] border-solid border-gray-300 rounded-[4px] bg-gray-100/40 cursor-pointer hover:bg-gray-300/60 duration-300 mx-1 text-[12px]"
          >
            {rule?.field} {rule?.operator}{" "}
            <span className="text-blue-500">
              {result} {remaining > 0 ? `,   +${remaining}` : ""}
            </span>
          </div>
        );
      })}
    </div>
  );
};
