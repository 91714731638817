function getMatchGroups(template: string, regex?: RegExp) {
  if (!regex) return null;
  const _regex = new RegExp(regex);

  const match = _regex.exec(template);
  return match?.groups;
}
export function extractRatioParts(template: string, regex?: RegExp): { numerator: string; denominator: string } | null {
  const matchGroups = getMatchGroups(template, regex);
  if (matchGroups) {
    return {
      numerator: matchGroups.event_name1,
      denominator: matchGroups.event_name2,
    };
  }
  return null;
}

export function extractRetentionEventName(template: string, regex?: RegExp): { event_name: string } | null {
  const matchGroups = getMatchGroups(template, regex);

  if (matchGroups) {
    return {
      event_name: matchGroups.event_name,
    };
  }
  return null;
}
