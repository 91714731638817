export const getUserInfo = () => {
  const tokenInfo = window.sessionStorage.getItem("token_info");
  if (!tokenInfo) return null;

  try {
    const parseToken = JSON.parse(tokenInfo);
    return {
      email: parseToken.email,
      name: parseToken.name,
      nickname: parseToken.nickname,
      sub: parseToken.sub,
    };
  } catch (error) {
    return null;
  }
};
