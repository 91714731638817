import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { Form, FormInstance } from "antd";
import React from "react";

export const PlatformField: React.FC<{ form?: FormInstance<object> }> = ({ form }) => {
  const { isViewMode } = getModeOfLocation();
  const productCodeWatch = Form.useWatch("product_code", form);
  const options = React.useMemo(() => {
    return [
      {
        label: "Android",
        value: productCodeWatch + "a",
      },
      {
        label: "IOS",
        value: productCodeWatch + "i",
      },
    ];
  }, [productCodeWatch]);

  return (
    <Form.Item
      name="platform"
      label={<span className="font-bold">Platform</span>}
      className="w-[20%] mb-0 pr-2"
      rules={[
        {
          required: true,
          message: "Please select platform",
        },
      ]}
    >
      <SelectWithSpinAndCustomMaxTag
        mode="multiple"
        disabled={!!isViewMode || !productCodeWatch}
        placeholder="Platform"
        options={options}
      />
    </Form.Item>
  );
};
