import React from "react";
import { Button, Alert, CollapseProps, Collapse, Tabs, Row, Col } from "antd";
import { ConsoleSqlOutlined, DeploymentUnitOutlined, ExperimentOutlined } from "@ant-design/icons";
// context
import { useExploreContext } from "@src/contexts/explore-context";

// types
import { IUpdateMethods } from "@src/types/query-builder";

// helper
import { playgroundActionUpdateMethods } from "@src/helpers/update-method-query";

// components
import OrderGroup from "@src/components/query-builder/order-group";
import QueryViewer from "@src/components/query-builder/query-viewer";
import LimitGroup from "@src/components/query-builder/limit-group";
import JsonViewer from "@src/components/query-builder/json-viewer";

// dropdown v2
import MemberGroupDimensions from "@src/components/query-builder/v2/member-group-dimensions";
import MemberGroupMeasures from "@src/components/query-builder/v2/member-group-measures";
import MemberGroupSegments from "@src/components/query-builder/v2/member-group-segment";
import MemberGroupFilterExplore from "@src/components/query-builder/v2/member-group-filter-explore";
import { suggestionOptions } from "@src/config";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { css } from "@emotion/react";

function ExploreQueryNew() {
  const { isViewMode } = getModeOfLocation();

  const {
    query,
    measures,
    availableMeasures,
    updateMeasures,
    dimensions,
    availableDimensions,
    updateFilters,
    timeDimensions,
    updateTimeDimensions,
    segments,
    availableSegments,
    updateSegments,
    error,
    isFetchingMeta,
    updateQuery,
  } = useExploreContext();
  const suggestions: CollapseProps["items"] = [
    {
      key: "1",
      label: "Common explorations",
      children: (
        <div>
          {suggestionOptions.map((opt: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Button
                  disabled={!!isViewMode}
                  className="mb-2 mr-2 bg-white"
                  onClick={() => {
                    updateQuery(opt.query);
                  }}
                >
                  {opt.name}
                </Button>
              </React.Fragment>
            );
          })}
        </div>
      ),
    },
  ];
  const errorStyles = {
    color: error?.message ? "red" : "black",
  };
  return (
    <div
      css={css`
        .ant-tabs-nav {
          position: sticky !important;
          top: 0 !important;
          z-index: 1 !important;
          background-color: white !important;
        }
      `}
      className=" pr-3"
    >
      {error?.message && <Alert className="mb-3" message="Error" description={error.message} type="error" showIcon />}
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: <span style={errorStyles}>Query builder</span>,
            icon: <ExperimentOutlined style={errorStyles} />,

            children: (
              <div>
                <Collapse size="small" style={{ width: "100%" }} items={suggestions} className="custom-collapse " />
                <Row gutter={16} className="my-2">
                  <Col span={24}>
                    <MemberGroupDimensions
                      title="Dimensions"
                      dimensions={dimensions}
                      timeDimensions={timeDimensions}
                      availableMembers={availableDimensions}
                      updateTimeMethods={updateTimeDimensions}
                      updateQuery={updateQuery}
                      query={query}
                      isFetchingMeta={isFetchingMeta}
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col span={24}>
                    <MemberGroupMeasures
                      title="Measures"
                      members={measures}
                      availableMembers={availableMeasures}
                      updateMethods={playgroundActionUpdateMethods(updateMeasures) as IUpdateMethods}
                      query={query}
                      updateQuery={updateQuery}
                      isFetchingMeta={isFetchingMeta}
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col span={24}>
                    <MemberGroupSegments
                      title="Segments"
                      members={segments}
                      availableMembers={availableSegments}
                      updateMethods={updateSegments}
                      query={query}
                      updateQuery={updateQuery}
                      isFetchingMeta={isFetchingMeta}
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col span={24}>
                    <MemberGroupFilterExplore
                      title="Filter"
                      availableMembers={availableDimensions.concat(availableMeasures as any)}
                      updateMethods={updateFilters}
                      query={query}
                      updateQuery={updateQuery}
                      isFetchingMeta={isFetchingMeta}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <OrderGroup
                      title="Order"
                      timeDimensions={timeDimensions}
                      dimensions={dimensions}
                      measures={measures}
                      query={query}
                      updateQuery={updateQuery}
                    />
                  </Col>
                  {/* <Col span={10} className="mt-2">
                    <LimitGroup title="Limit" query={query} isQueryPresent={isQueryPresent} updateQuery={updateQuery} />
                  </Col> */}
                </Row>
              </div>
            ),
          },
          {
            key: "2",
            label: <span style={errorStyles}>SQL Query</span>,
            icon: <ConsoleSqlOutlined style={errorStyles} />,
            disabled: !!error,
            children: <QueryViewer query={query} />,
          },
          {
            key: "3",
            label: "JSON Query",
            icon: <DeploymentUnitOutlined />,
            children: <JsonViewer query={query} />,
          },
        ]}
      />
    </div>
  );
}

export default ExploreQueryNew;
