import { getUserInfo } from "@src/util/user-propertiles/get-user-info";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  //   storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  //   messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

let analytics: any = null;
const isEnableAnalytics = import.meta.env.VITE_ENABLE_ANALYTICS;
if (isEnableAnalytics) {
  const app = initializeApp(firebaseConfig);
  const token = getUserInfo();
  if (token) {
    analytics = getAnalytics(app);
    setUserProperties(analytics, {
      name: token?.name,
      nickname: token?.nickname,
    });
    setUserId(analytics, token?.sub);
  }
}

export const dispatchLogEvent = (eventName: string, eventParams = {}) => {
  if (!isEnableAnalytics) return;
  logEvent(analytics, eventName, { ...eventParams });
};
